/*                    */
/*   PlayerGameBoard  */
/*                    */

.gameBoard-container {
  position: absolute;
  top: 64px;
  bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

/*                    */
/*      SideBar       */
/*                    */

.sideBar-wrapper {
  background-color: #b9b3b3;
  padding: 0px 5px;
  height: 100%;
  max-width: 235px;
}

.players-text {
  margin: -1px 0px;
}

.players-text-header {
  margin: 0px 0px -3px;
  font-size: x-small;
}

.add-color-red {
  color: red;
  text-shadow: 0px 0px 2px black;
}
.add-glow-redHint,
.add-glow-redGuess {
  color: red;
  text-shadow: 0px 0px 2px black;
}

.add-color-blue {
  color: blue;
  text-shadow: 0px 0px 2px black;
}

.add-glow-blueHint,
.add-glow-blueGuess {
  color: blue;
  text-shadow: 0px 0px 2px black;
}

.current-turn-info-container,
.playerInfo-container,
.hint-container,
.allPlayersInfo-container,
.chat-container {
  background-color: slategray;
  width: 100%;
  padding: 2px 5px;
  margin: 5px 3px;
  border-radius: 5px;
  border: inset;
}

.allPlayersInfo-container {
  overflow-y: scroll;
}

.current-turn-text {
  margin: 0px;
}

.spyMaster-hint-text-wrapper {
  display: flex;
  justify-content: space-around;
}

.spyMaster-hint-text {
  margin: 3px 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#hintInput {
  background-color: cadetblue;
}

.input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: lightgray;
  border: 1px solid black;
  border-radius: 5px;
}

.word-hint-wrapper {
  flex-basis: 75%;
}

.hint-error-toast-text {
  display: block;
  margin: 4px;
}

.hint-error {
  margin-left: 14px;
}

.hintNumber {
  display: block;
  height: 2rem;
  border: 1px solid #b3abab;
}

.input {
  height: 25px !important;
  margin-bottom: 4px !important;
}

.submit-hint-btn {
  width: 100%;
  margin: 3px 0px;
  height: 30px;
}

.submit-chat {
  width: 100%;
  margin-top: 3px;
  height: 30px;
}

.end-turn-btn {
  width: 100%;
  height: 30px;
  border: 1px solid #697888;
}

.leave-game-btn {
  width: 100%;
  height: 30px;
  border: 1px solid #697888;
}

.log-wrapper {
  height: calc(100% - 83px);
  overflow-y: scroll;
  margin: 3px 0px;
}

.messageSender {
  font-size: small;
  margin: 0px;
  margin-bottom: -8px;
  margin-left: 6px;
  text-shadow: 0px 0px 5px black;
  color: white;
}

.message-wrapper {
  background-image: linear-gradient(
    #1e5e57 10%,
    #00796b 25%,
    #00796b 75%,
    #1e5e57 90%
  );
  border: 1px solid;
  width: 97%;
  color: black;
  padding: 2px 4px;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 3px;
}

.message-text {
  margin: 0px;
  text-shadow: 0px 0px 4px white;
  font-size: 15px;
}

/*                    */
/*      PlayArea      */
/*                    */

.playArea-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 30px;
  background-image: url(/images/wood-background.jpg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.score-wrapper {
  color: white;
  font-size: 1.4rem;
  text-shadow: 0px 0px 5px black;
}

.cards-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 90%;
  max-width: 90%;
  min-width: 500px;
  padding: 10px;
}

/*                    */
/*      GameCard      */
/*                    */

.cardContainer {
  position: relative;
  display: flex;
  flex: 1 1 calc(20% - 14px);
  justify-content: center;
  align-items: center;
  height: 8rem;
  min-width: 9.4rem;
  padding: 7px;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card {
  transform: rotateY(180deg);
}

/* position front and back */
.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 6px;
}

.card-front {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Merriweather;
  border: outset;
  border-right-color: #e1dbdb;
  border-bottom-color: #e1dbdb;
  border-top-color: #fbfbfb;
  border-left-color: #fbfbfb;
}

.card-back {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotateY(180deg);
}

.black {
  color: white;
}

.card-color-red {
  background-color: #c93939;
  border-right-color: #dd8080;
  border-bottom-color: #dd8080;
  border-top-color: #c06464;
  border-left-color: #c06464;
}

.card-color-blue {
  background-color: lightskyblue;
  border-right-color: #abd0e1;
  border-bottom-color: #abd0e1;
  border-top-color: #86cfd4;
  border-left-color: #86cfd4;
}

.card-color-white {
  background-color: white;
}

.card-color-black {
  background-color: black;
  color: white;
  border-right-color: #918787;
  border-bottom-color: #918787;
  border-top-color: #3a3737;
  border-left-color: #3a3737;
}

/*                    */
/*     GameLobby      */
/*                    */

.gameLobby-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-color: #0000007a;
  background-image: url(/images/main-image-original.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.lobby-header-text {
  font-family: rock salt;
  color: white;
  font-size: 1.5rem;
  margin-bottom: 35px;
}

.team-select-wrapper {
  display: flex;
  justify-content: space-around;
  /* min-height: 50%; */
  padding: 12px;
  background-color: #0202029e;
  border-radius: 7px;
}

.blue-team-wrapper,
.red-team-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 1;
}

.agent-image-blue,
.agent-image-red {
  max-width: 275px;
  max-height: 183px;
  margin: 7px;
  transition: all 0.5s ease-in-out;
}

.agent-image-blue:hover {
  box-shadow: 0px 0px 15px blue;
}

.agent-image-red:hover {
  box-shadow: 0px 0px 15px red;
}

.spyMaster-check-wrapper {
  border: 1px solid white;
  background-image: linear-gradient(#6b6666, #00000080, #00000080, #6b6666);
  border-radius: 25px;
  margin: 0px 7px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  box-shadow: 0px 0px 15px darkgrey;
}

.spyMaster-check-wrapper:hover {
  box-shadow: 0px 0px 20px white;
}

.red-spyMaster-text,
.blue-spyMaster-text,
.lobby-header-textgi {
  color: white;
  text-align: center;
  font-family: "Rock Salt", cursive;
  text-shadow: 0px 0px 10px white;
  transition: all 0.5s ease-in-out;
}

.red-spyMaster-selected {
  color: crimson;
}

.blue-spyMaster-selected {
  color: lightskyblue;
}

/*                    */
/*    InviteFriend    */
/*                    */

.inviteFriend-wrapper {
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #000000aa;
}

.formWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: lightcyan;
  padding-bottom: 20px;
}

.inviteFriendForm {
  padding: 20px 15px 0px;
  margin-bottom: 7px;
}

.invite-form-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.invite-btn {
  width: 120px;
}
/*    notification    */
/*                    */

.notification-container {
  position: absolute;
  top: -60px;
  padding: 5px 20px;
  transition: all 0.75s ease-in-out;
}

.popIn {
  top: 9px;
  background-image: linear-gradient(transparent 5%, #4f0d11 20%, #311f1f 80%);
  color: white;
  font-family: Merriweather;
  font-size: 1.4rem;
}

/*                    */
/*     media query    */
/*                    */

@media screen and (max-width: 1000px) {
  .card-inner {
    flex: 1 1 calc(25% - 14px);
  }
}

@media (max-width: 800px) {
  .playArea-container {
    padding: 15px 0px;
  }
  .cards-wrapper {
    min-width: 0px;
  }
  .card-inner {
    flex: 1 1 calc(32% - 14px);
  }
  .team-select-wrapper {
    flex-direction: column;
  }

  .deep-cover-agent {
    display: none;
  }
}

@media (max-width: 550px) {
  .agent-image-blue,
  .agent-image-red {
    width: 150px;
    margin: 7px;
  }
}

@media (max-width: 540px) {
  .gameBoard-container {
    top: 56px;
  }
}
