.JoinGameLobby-container {
  background-image: url("./main-image.jpg");
  background-size: cover;
  height: 100vh;
  padding-top: 30px;
}
.JoinGame-container {
  margin: 10%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.JoinGame-header-text {
  text-align: center;
  margin: 0px;
}

.JoinGame-container > button {
  margin: 5px;
}

.JoinGame-container > h2 {
  margin: 15px;
  font-size: 4vh;
  color: white;
  font-family: "Avant Garde";
}
h2 {
  color: white;
  font-family: "Avant Garde";
}
