.ChooseGame-container {
  background-image: url("./main-image.jpg");
  background-size: cover;
  height: calc(100vh - 64px);
  display: flex;
  justify-content: space-around;
  align-items: center;
}
a {
  color: white;
}
