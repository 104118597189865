.title-container {
  background-image: url("./main-image.jpg");
  background-size: cover;
  display: flex;
  padding-top: 1%;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.title-button {
  font-size: 24px;
  padding: 15px;
  background-color: teal;
  border-radius: 15px;
  border: 1px solid black;
}

a {
  color: white;
}
