.navbar {
  background-image: url(/images/navbar-texture.jpg);
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 10;
}

.controls-btn {
  margin-left: 6px;
  background-color: #0e3732;
  box-shadow: 0px 2px 2px black;
}

.header-logo {
  font-family: special elite;
}

.logo-container {
  border-radius: 4px;
}

.btns-right-container {
  margin-right: 6px;
}
